export enum NAVIGATION {
    HOME = "/",
    PROCESS = "/process",
    ONBOARDING = "/process/onboarding",
    KYC = "/process/kyc",
    TRACKING = "/process/tracking",
    LOGIN = "/auth/login",
    REGISTER = "/auth/register",
    FORGOT_PASSWORD = "/auth/forgot-password",
    CHANGE_PASSWORD = "/auth/change-password",
    VERIFY_EMAIL = "/auth/verify"
}